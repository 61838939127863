@font-face {
  font-family: 'cobbler';
  src: local('cobbler'), url(../public/fonts/Cobbler-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'cobbler';
  font-weight: 900;
  src: local('cobbler'), url(../public/fonts/Cobbler-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'cobbler';
  font-weight: 300;
  src: local('cobbler'), url(../public/fonts/Cobbler-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'cobbler';
  font-weight: 600;
  src: local('cobbler'), url(../public/fonts/Cobbler-Semi-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'cobbler';
  font-weight: 700;
  src: local('cobbler'), url(../public/fonts/Cobbler-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'cobbler';
  font-weight: 500;
  src: local('cobbler'), url(../public/fonts/Cobbler-Medium.ttf) format('truetype');
}

#root{
  height: 100%;
}
h1{
  font-family: cobbler;
  font-size: 2em;
  font-weight: 700;
  color : #212121;
  line-height: 1em;
}

h2{
  font-family: cobbler;
  line-height: 1em;
  font-size: 1.5em;
  font-weight: 700;
  color : #212121;

} 

h3{
  font-family: cobbler;
  font-size: 1.25em;
  line-height: 1em;
  font-weight: 600;
  color : #212121;

} 

h4{
  font-family: cobbler;
  line-height: 1em;
  font-size: 1.17em;
  font-weight: 500;
  color : #212121;

} 
label{
  color : #212121;
  line-height: 1em;
  font-family: cobbler;
  font-weight: 600;

}
p{
  color : #212121;
  line-height: 1em;

  font-family: cobbler;
}

.router-link{
  color : #6f58e4;
  font-weight: 600;
  text-decoration-line: underline;
}

span{

  font-family: cobbler;
}


button{
  font-family: cobbler;
  
}

.App {

  text-align: center;
  font-family: cobbler;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
